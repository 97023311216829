/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }
  .rc-form .file-upload input[type="file"] {
    display: none; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rc-video .thumb::after,
.rc-video-left .thumb::after,
.rc-video-right .thumb::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); }

.rc-two-cols {
  margin-bottom: .5rem; }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h1 {
  margin-bottom: 25px;
  font-size: 2.3rem;
  font-weight: lighter;
  color: #050101; }

.h2, h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: lighter;
  color: #1F4DA5; }

.h3, h3 {
  margin-bottom: 20px;
  font-size: 1.7rem;
  font-weight: lighter;
  color: #15A3E1; }

.h4, h4 {
  margin-bottom: 20px;
  color: #15A3E1;
  font-size: 1.4rem; }

.h5, h5 {
  margin-bottom: 20px;
  color: #050101; }

.h6, h6 {
  margin-bottom: 10px;
  color: #050101; }

a {
  color: #15A3E1; }

.lead {
  font-size: 1rem;
  font-style: italic; }

/* buttons */
.btn {
  font-style: italic;
  font-size: .9rem;
  text-transform: uppercase;
  transition: color .2s ease-out, background .2s ease-out; }

.btn-primary {
  color: #fff !important;
  background-color: #15A3E1;
  border: none;
  padding: .5rem .7rem .5rem 1rem;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none;
    background-color: #2761d0; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    background-color: #1F4DA5; }
  .btn-primary:active, .btn-primary.active {
    background-color: #2761d0; }

.btn-warning {
  color: #fff !important;
  background-color: #FD7018;
  border: none;
  padding: .5rem .7rem .5rem 1rem;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-warning:focus, .btn-warning.focus, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:focus, .btn-warning.active.focus {
    outline: none;
    background-color: #e05702; }
  .btn-warning:hover, .btn-warning.hover, .btn-warning:focus {
    background-color: #fd8f4b; }
  .btn-warning:active, .btn-warning.active {
    background-color: #e05702; }

.btn-primary::after,
.btn-warning::after {
  content: '\e803';
  margin-right: 0 !important;
  margin-left: .5rem !important;
  opacity: 0.5; }

/* forms */
.form-control {
  font-size: .9rem;
  border-radius: 0;
  border-color: #B4B4B4; }
  .form-control:focus {
    border-color: #15A3E1; }

/* navbar */
.navbar {
  padding: 0; }

.navbar-nav .nav-item.dropdown:hover > .dropdown-menu {
  margin: 0;
  display: block; }

.navbar-nav .nav-link {
  color: #1F4DA5;
  text-transform: uppercase;
  font-weight: normal; }

.navbar-nav .active > .nav-link {
  font-weight: bold;
  position: relative; }
  .navbar-nav .active > .nav-link::after {
    content: none; }

.navbar-toggler {
  text-transform: uppercase;
  color: #1F4DA5;
  width: auto;
  height: auto;
  font-size: .9rem;
  line-height: 1;
  cursor: pointer;
  background-color: rgba(21, 163, 225, 0.1); }
  .navbar-toggler[aria-expanded="true"] {
    color: #fff;
    background-color: #15A3E1;
    border-radius: .25rem .25rem 0 0; }

.navbar-toggleable-md .navbar-nav {
  margin-top: 0;
  margin-bottom: 0; }

/* dropdown */
.dropdown-menu {
  padding: 0; }

.dropdown-item {
  padding: 3px .5rem;
  font-size: .9rem;
  text-transform: uppercase;
  color: #1F4DA5; }

.dropdown-toggle::after {
  border-top-color: #15A3E1; }

.dropdown--lang {
  font-size: .9rem; }
  .dropdown--lang .dropdown-menu {
    min-width: 2.5rem;
    left: auto;
    right: 0; }
  .dropdown--lang .dropdown-item {
    padding: 3px .5rem; }
  .dropdown--lang .dropdown-toggle {
    border: none;
    padding: .425rem .2rem;
    color: #1F4DA5; }

/* carousel */
.carousel-control.left {
  background-image: none; }

.carousel-control.right {
  background-image: none; }

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 100%;
  max-width: 80px;
  height: 0;
  margin-top: 0;
  padding-bottom: 100%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto; }
  .carousel-control .icon-next::before,
  .carousel-control .icon-prev::before {
    content: none; }

.carousel-control .icon-next {
  margin-right: 0;
  right: 10px;
  background-image: url("/img/slider-next.svg"); }

.carousel-control .icon-prev {
  margin-left: 0;
  left: 10px;
  background-image: url("/img/slider-prev.svg"); }

.carousel-indicators {
  margin-bottom: 0; }
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    margin: 0 3px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none; }
  .carousel-indicators .active {
    margin: 0 3px; }

.breadcrumb {
  background: none; }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

input:focus::-moz-placeholder {
  color: transparent; }

input:focus:-ms-input-placeholder {
  color: transparent; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: .9rem;
  overflow: hidden;
  color: #606060; }

/*
 *	UTILITIES
 */
.hidden {
  display: none !important; }

.flex-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }

.flex-stretch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .flex-stretch > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }

.row--fatcols {
  margin-left: -30px;
  margin-right: -30px; }
  .row--fatcols > [class^='col'] {
    padding-left: 30px;
    padding-right: 30px; }

.clear {
  clear: both; }

.link-black {
  color: #606060; }

/*
 *	GENERAL
 */
.more {
  font-size: .85rem;
  color: #1F4DA5;
  text-transform: uppercase; }

.decoration-groene-energie-home {
  display: none !important; }

.decoration {
  position: relative;
  z-index: 2; }
  .decoration::before, .decoration::after {
    float: right;
    content: '';
    display: block;
    clear: both;
    border-radius: 0 0 0 5px; }
  .decoration::before {
    width: 50%;
    height: 15px;
    background-color: #1F4DA5; }
  .decoration::after {
    width: 25%;
    height: 7px;
    background-color: #15A3E1; }

.decoration-groene-energie-footer {
  color: #80ac52 !important; }
  .decoration-groene-energie-footer img {
    max-width: 100px; }
  .decoration-groene-energie-footer p {
    font-family: "Open Sans";
    font-size: .9rem;
    color: #80ac52 !important; }
  .decoration-groene-energie-footer strong {
    font-family: "Open Sans" !important;
    font-weight: Bold !important;
    font-size: 1.2rem !important; }

.decoration--reverse::before, .decoration--reverse::after {
  float: left;
  border-radius: 0 0 5px 0; }

.decoration--reverse::before {
  width: 65%; }

.link-block {
  display: block;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: background .2s ease-out; }
  .link-block:hover, .link-block:focus {
    text-decoration: none; }

.link-block__icon {
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #1F4DA5;
  color: #fff;
  font-size: 1.5rem;
  transition: background .2s ease-out; }

.link-block__text {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 0 10px;
  line-height: 40px;
  font-style: italic;
  font-weight: bold;
  color: #050101;
  text-transform: uppercase; }

.link-block__arrow {
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: rgba(0, 0, 0, 0.2);
  font-size: 1.2rem; }

.link-block:hover .link-block__text, .link-block:focus .link-block__text {
  color: #1F4DA5; }

.link-block:hover .link-block__icon, .link-block:focus .link-block__icon {
  background-color: #15A3E1; }

.link-block:active, .link-block.active {
  background-color: rgba(0, 0, 0, 0.1); }

.link-block--light {
  background-color: rgba(255, 255, 255, 0.2); }
  .link-block--light .link-block__text {
    color: #fff; }
  .link-block--light .link-block__arrow {
    color: #15A3E1;
    opacity: 1; }
  .link-block--light:hover, .link-block--light:focus {
    background-color: rgba(255, 255, 255, 0.4); }
    .link-block--light:hover .link-block__text, .link-block--light:focus .link-block__text {
      color: #fff; }
  .link-block--light:active, .link-block--light.active {
    background-color: rgba(31, 77, 165, 0.5); }

/*
 *	HEADER
 */
.header {
  padding: 45px 0 35px; }

.header-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.headerbottom-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 32px; }

.header__lang {
  font-size: 0; }
  .header__lang .dropdown {
    display: inline-block; }
  .header__lang::before {
    content: '|';
    margin: 0 10px;
    display: inline-block;
    font-size: .9rem;
    color: #1F4DA5; }

.header__social {
  margin: 0 0 0 20px;
  padding: .25rem 0; }
  .header__social li {
    display: inline-block; }
  .header__social li + li {
    margin-left: .5rem; }
  .header__social a {
    display: block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    background-color: #B4B4B4;
    border-radius: 50%;
    transition: background .15s ease-out;
    text-decoration: none; }
    .header__social a:hover, .header__social a:focus {
      background-color: #15A3E1; }
    .header__social a:active {
      background-color: #1F4DA5; }

.logo {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  float: left;
  width: 280px;
  max-width: 100%;
  margin-bottom: 10px; }
  .logo a {
    margin-right: auto;
    display: inline-block; }

/*
 *	BANNER
 */
.banner {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #B4B4B4;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .banner .carousel {
    width: 100%;
    height: 100%; }
  .banner .carousel-inner {
    height: 100%; }
  .banner .carousel-item {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .banner::before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-radius: 100%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); }

.banner--content {
  height: 520px; }
  .banner--content::before {
    content: none; }

.banner--small {
  z-index: 1;
  height: 200px; }
  .banner--small::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit; }

.mobile-banner {
  width: 100%;
  padding-bottom: 56.6%;
  height: 0;
  position: relative; }

#video-mobile {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

/*
 *	PIC BANNER
 */
.picbanner {
  background-color: #15A3E1;
  color: #fff; }
  .picbanner .title {
    color: #fff; }

.picbanner__img {
  z-index: 1;
  position: absolute;
  width: 100%;
  top: -20px;
  right: 15px;
  bottom: -20px;
  left: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .picbanner__img .img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .picbanner__img::before {
    z-index: 0;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    border-radius: 100%; }

.cta-blue {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  z-index: 1;
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.cta-blue__img {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  .cta-blue__img img {
    width: 100%; }

.cta-blue__desc {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 25px;
  background-color: #1F4DA5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .cta-blue__desc .title {
    font-size: 1.2rem; }
  .cta-blue__desc p {
    color: rgba(255, 255, 255, 0.8); }
  .cta-blue__desc .buttons {
    margin-top: auto; }
  .cta-blue__desc .btn + .btn {
    margin-left: 1rem; }

/*
 *	SHADED COLS
 */
.shaded-cols {
  position: relative;
  background-color: #F5F5F5; }
  .shaded-cols::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    background-color: #fff; }

.shaded-cols .container > .row > [class^='col']:nth-child(2) {
  background-color: #EFEFEF; }

/*
 *	PRODUCT (CATEGORIES)
 */
.product {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1px solid #F5F5F5; }

.product__img {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  .product__img a {
    position: relative;
    display: block;
    width: 100%; }
  .product__img img {
    width: 100%; }

.product__hover {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(31, 77, 165, 0.5), rgba(21, 163, 225, 0.5));
  text-align: center;
  color: #fff;
  font-weight: bold;
  opacity: 0;
  transition: opacity .2s ease-out;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }
  .product__hover::before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    background-image: url("/img/plus.svg");
    background-size: 100% auto; }

.product__desc {
  padding: .5rem 0; }
  .product__desc .title {
    font-size: 1.2rem;
    font-weight: lighter;
    color: #15A3E1; }
  .product__desc a:hover, .product__desc:focus {
    text-decoration: none;
    color: #1F4DA5; }
    .product__desc a:hover .title, .product__desc:focus .title {
      color: #1F4DA5; }

.product a:hover .product__hover,
.product a:focus .product__hover {
  opacity: 1; }

/*
 *	SUBPAGE
 */
.subpage {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 25px; }

.subpage__title {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%; }
  .subpage__title a {
    color: #15A3E1; }
  .subpage__title .title {
    font-size: 1.1rem;
    font-weight: lighter; }

.subpage__img {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  max-width: 25%; }
  .subpage__img a {
    display: block; }
  .subpage__img img {
    width: 100%; }

.subpage__desc {
  -ms-flex: 1 0 75%;
  flex: 1 0 75%;
  max-width: 75%;
  padding-left: 20px; }
  .subpage__desc p  {
    margin-bottom: 3px; }

/*
 *	VARIA
 */
.varia {
  padding: 90px 0 0;
  background-color: #F5F5F5; }

/*
 *	ASIDE
 */
.aside {
  position: relative;
  padding: 90px 20px 45px;
  background-color: #F5F5F5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .aside::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -9999px;
    width: 9999px;
    background-color: #F5F5F5; }

.cta-aside {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  margin: auto 0 20px;
  text-align: center; }
  .cta-aside .title {
    margin-bottom: 20px;
    font-size: 1.1rem;
    color: #FD7018; }

.img-aside {
  position: relative;
  z-index: 1;
  margin: 0 -40px 60px; }
  .img-aside img {
    width: 100%;
    height: auto; }

.nav--aside {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 30px; }
  .nav--aside ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #B4B4B4; }
  .nav--aside .nav-item {
    border-bottom: 1px solid #B4B4B4; }
  .nav--aside .nav-link {
    padding: 3px 15px;
    text-transform: uppercase;
    color: #606060; }
    .nav--aside .nav-link:hover, .nav--aside .nav-link:focus {
      color: #15A3E1; }
    .nav--aside .nav-link:active {
      color: #1F4DA5; }
  .nav--aside .active .nav-link {
    color: #15A3E1; }

/*
 *	FOOTER
 */
.footer {
  margin-top: 100px;
  clear: both; }

.footer__top {
  position: relative;
  color: #606060; }
  .footer__top a {
    color: #606060; }
  .footer__top i {
    color: #15A3E1; }
  .footer__top .btn {
    margin-top: auto; }
  .footer__top::after {
    content: '';
    display: block;
    width: 45%;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 5px 0 0 0;
    background-color: #15A3E1; }

.footer__credits {
  color: #fff;
  background-color: #1F4DA5;
  padding: 20px 0;
  font-size: .8rem; }
  .footer__credits::after {
    content: '';
    display: table;
    clear: both; }
  .footer__credits a {
    color: #fff; }

.footer-nav {
  padding-left: 0; }
  .footer-nav ul {
    margin: 0;
    padding-left: 0; }
  .footer-nav li {
    margin-bottom: .5rem;
    list-style: none; }
    .footer-nav li::before {
      content: '-';
      margin-right: .5rem; }
  .footer-nav a {
    font-weight: bold;
    text-transform: uppercase;
    color: #606060; }

.footer-list {
  list-style: none;
  padding: 0;
  line-height: 1.2rem; }

/*
 *	RECAPTCHA
 */
.g-recaptcha {
  margin: 20px 0; }

.grecaptcha-badge {
  max-width: 100%;
  overflow: hidden; }

.with-errors {
  color: #d9534f; }

/*
 *  POPUP
 */
.popup .modal-header {
  border-bottom: none; }

.popup .form-control {
  height: 55px; }

.popup .input-group .btn {
  height: 55px; }

.popup .text-small {
  font-size: .8rem; }

/*
 *	IMPORTS
 */
@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?98781193");
  src: url("/font/fontello.eot?98781193#iefix") format("embedded-opentype"), url("/font/fontello.woff2?98781193") format("woff2"), url("/font/fontello.woff?98781193") format("woff"), url("/font/fontello.ttf?98781193") format("truetype"), url("/font/fontello.svg?98781193#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.svg?98781193#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before, .btn-primary::after, .btn-warning::after {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-phone:before {
  content: '\e800'; }

/* '' */
.icon-mail:before {
  content: '\e801'; }

/* '' */
.icon-cancel:before {
  content: '\e802'; }

/* '' */
.icon-right-open:before {
  content: '\e803'; }

/* '' */
.icon-left-open:before {
  content: '\e804'; }

/* '' */
.icon-ok:before {
  content: '\e805'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-angle-circled-left:before {
  content: '\f137'; }

/* '' */
.icon-angle-circled-right:before {
  content: '\f138'; }

/* '' */
.icon-fax:before {
  content: '\f1ac'; }

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 575px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 1199px) {
  .img-aside {
    margin: 0 auto 25px;
    width: auto; } }

@media (max-width: 991px) {
  /* header */
  .header {
    padding: 30px 0 20px; }
  /* aside */
  .aside {
    padding: 25px 0; }
    .aside::after {
      z-index: -1;
      right: 50%;
      width: 99999px;
      margin-right: -49999px; }
  /* nav */
  .navbar {
    z-index: 50;
    position: absolute;
    top: 0;
    right: 0;
    -ms-flex-order: 1;
    order: 1; }
  .navbar-nav {
    background-color: #dff3fc;
    border: 1px solid #15A3E1; }
    .navbar-nav .nav-item + .nav-item {
      border-top: 1px solid rgba(21, 163, 225, 0.2); }
    .navbar-nav .nav-link,
    .navbar-nav .dropdown-item {
      padding: .425rem .8rem; }
    .navbar-nav .dropdown-menu {
      border-radius: 0;
      border: none; }
  .navbar-nav .active > .nav-link::after {
    height: 2px;
    border-radius: 0; }
  .navbar-toggler {
    float: right; }
  .navbar-toggleable-md {
    clear: both; }
  /* dropdown */
  .header__lang::before {
    content: none; }
  /* picbanner */
  .picbanner__img {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: relative;
    width: 100%;
    height: 350px;
    margin-bottom: 2rem; }
  .shaded-cols::before {
    content: none; }
  /* banner */
  .banner--content {
    height: 400px; } }

@media (max-width: 767px) {
  /* header */
  .header__social {
    display: none; }
  /* banner */
  .banner {
    height: 250px; }
  .banner--content  {
    height: 250px; }
  .banner--small {
    height: 150px; }
  /* utils */
  .row--fatcols {
    margin-left: -15px;
    margin-right: -15px; }
    .row--fatcols > [class^="col"] {
      padding-left: 15px;
      padding-right: 15px; }
  /* title */
  .h1, h1 {
    font-size: 2rem; }
  .h2, h2 {
    font-size: 1.8rem; }
  .h3, h3 {
    font-size: 1.5rem; }
  .h4, h4 {
    font-size: 1.2rem; }
  .h5, h5 {
    font-size: .9rem; }
  .h6, h6 {
    font-size: .9rem; } }

@media (max-width: 485px) {
  .header-row {
    padding-right: 0; }
  .header {
    padding: 20px 0 15px; }
  .navbar {
    position: static;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    width: 100%; }
  .navbar-toggler {
    width: 100%;
    background-color: rgba(21, 163, 225, 0.1); } }

@media (max-width: 370px) {
  .header-row {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .logo {
    -ms-flex-order: 0;
    order: 0;
    width: auto;
    -ms-flex: 1 0 80%;
    flex: 1 0 80%; }
  .header__lang {
    padding-left: 10px;
    -ms-flex-order: 1;
    order: 1;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }
  .navbar {
    -ms-flex-order: 2;
    order: 2;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; } }

@media (min-width: 992px) {
  .decoration-groene-energie-home {
    font-family: "Open Sans";
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    position: relative;
    z-index: 3;
    margin-top: -60px;
    float: right;
    width: 50%;
    height: 60px;
    font-weight: lighter;
    font-size: 1.4vw;
    letter-spacing: .1rem;
    border-radius: 5px 0 0 0;
    background-image: linear-gradient(90deg, #528b3e, #4f893d, #83b550); }
    .decoration-groene-energie-home small {
      font-weight: 300 !important; }
    .decoration-groene-energie-home strong {
      font-weight: bold; }
    .decoration-groene-energie-home img {
      margin-bottom: -9px;
      width: 114px; } }

/*# sourceMappingURL=app.css.map */
